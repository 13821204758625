import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { bob } from '@wagmi/core/chains'

const icon = chainsSvgs.bob
const networkType: IWeb3NetworkType = 'main'
const chainId = bob.id

const chainInfo: IWeb3ChainType = {
  chain: bob,
  id: chainId,
  type: 'EVM',
  name: bob.name,
  icon: icon,
  networkType
}

export default chainInfo
